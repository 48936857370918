@media (max-width: 1342px) {
  main,
  .wrap {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  h2 {
    margin-top: 117px;
  }
}

@media (max-width: 400px) {
  main > section {
    /*margin: 0 20px;*/
  }
}
