@media (max-width: 1120px) {
  .slideshow-container {
    .slideshow-wrapper {
      padding: 56px 0 0 0;
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
    .slideshow {
      width: 100%;
    }
    .next,
    .prev {
      z-index: 500;
      margin-left: 0;
      margin-right: 0;
    }
    .next {
      right: 10px;
    }
    .prev {
      left: 10px;
    }
  }
  .back {
    margin-left: 15px;
  }
}

@media (max-width: 960px) {
  .slideshow-container {
    .slideshow-wrapper {
      padding-top: $lightbox-titlebar-height-larger;
    }
    .titlebar {
      height: $lightbox-titlebar-height-larger;
    }
    .price-status {
      clear: left;
    }
    .price {
      border-left: none;
    }
    .next,
    .prev {
      margin-top: -22px + ($lightbox-titlebar-height-larger / 2);
    }
  }
}

@media (max-width: 640px) {

}


@media (max-width: 490px) {
  .slideshow-container {
    .slideshow-wrapper {
      padding-top: $lightbox-titlebar-height-larger-2;
    }
    .titlebar {
      height: $lightbox-titlebar-height-larger-2;
    }
    .preview-sheet {
      clear: left;
      border-left: none;
    }
    .status {
      border-right: none;
    }
    .next,
    .prev {
      margin-top: -22px + ($lightbox-titlebar-height-larger-2 / 2);
    }
  }
}

@media (max-width: 450px) {
  .slideshow-container {
    .slideshow-wrapper {
      padding-top: $lightbox-titlebar-height-larger-3;
    }
    .titlebar {
      height: $lightbox-titlebar-height-larger-3;
    }
    .address {
      clear: left;
      border-left: none
    }
    .title {
      border-right: none;
    }
    .next,
    .prev {
      margin-top: -22px + ($lightbox-titlebar-height-larger-3 / 2);
    }
  }
}
