html {
  font-size: 62.5%;
}

body {
  color: $text-color;
  font: normal 300 2.1rem/3.6rem 'Open Sans', Arial, sans-serif;
  /*-webkit-font-smoothing: subpixel-antialiased;*/
}

a {
  color: $link-color;
  text-decoration: none;

  transition: color 300ms ease-in-out;
}

h2, h3, h4, h5, h6 {
  font: normal 3.6rem/1 'Oswald', Arial, sans-serif;
  color: $darker-maroon;
  text-transform: uppercase;
  margin: 0;
  /*letter-spacing: 0.15em;*/
  @include letter-spacing(150);
}

h2 {
  margin: 67px 0 58px 0;
}

h3 {
  font-size: 1.8rem;
}

p {
  margin: 3.6rem 0;
}

.smaller {
  font-size: 1.6rem;
}

.centered {
  text-align: center;
}

.error {
  color: $header-text-color;
}

img {
  &.full {
    display: block;
    max-width: 100%;
  }
}

.wrap,
main {
  width: 1342px;
  margin: 0 auto;
}
.wrap {
  position: relative;
}

main {
  padding-top: $header-height;
}

nav {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}
nav ul {
    @extend %no-list;
}
main > section,
.side-margins {
  margin: 0 44px;
}

section {
  li a,
  strong {
    color: $darker-maroon;
    font-weight: 600;
  }
}

section {
  &.margins {
    $section-margins: 125px;
    margin-top: $section-margins;
    margin-bottom: $section-margins;
  }
}

/* move this */
.full-image {
  img {
    display: block;
    width: 100%;
  }
  border-bottom: 78px solid $maroon-color;
}

button,
  a.button {
    background: $maroon-color;
    color: $navigation-link-color;
    border: none;
    padding: 12px 21px;
    font-family: 'Oswald';
    font-size: 1.2rem;
    line-height: 1;
    @include letter-spacing(50);

    transition: opacity 300ms ease-in-out;

    &:hover {
      opacity: 0.75
    }
  }
