@media (max-width: 510px) {
  footer {
    nav {
      li {
        float: none;
        a {
          border-right: none;
          line-height: 2;
        }
      }
    }
    p {
      padding: 0 20px;
      br {
        display: none;
      }
    }
  }
}
