.links-list {
  @extend %grid-list;
  @extend %properties-base;

  text-transform: uppercase;
  /* fix for margins on list items */
  margin-right: -42px;
  margin-left: 4px;

  > li {
    position: relative;

    margin-right: 41px;
    margin-bottom: 31px;

    width: 282px;
    height: 369px;
  }
  img {
    height: 241px;
    border: 5px solid $lighter-gray;
  }
  h3, h4, div {
    margin-left: 17px;
    margin-right: 17px;
  }
  div {
    font-size: 1.4rem;
    @include letter-spacing(150);
  }

  padding-bottom: 60px;
}
