.left-col {
  display: table;

  nav, aside, .left, section {
    vertical-align: top;
    display: table-cell;

    padding-bottom: 60px;
  }

  nav, aside, .left {
    width: 327px;
    background: $left-nav-background;
    padding-top: 49px;
    font-size: 1.4rem;
    line-height: 1.2;

    li {
      &:first-child a {
        border-top: none;
      }
    }

    a, h4 {
      @include letter-spacing(50);
    }
    a {
      color: $left-nav-link-color;
      &:hover {
        color: $darker-maroon;
      }
    }
    .active a {
      color: $darker-maroon;
    }
    h4 {
      font-size: 1.4rem;
      line-height: 1.2;
      color: $text-color;
      border-top: none;
    }
    ul ul {
      margin-top: 18px;
    }
  }

  nav {
    a, h4 {
      padding: 17px 42px;
      display: block;
      border-top: 1px solid white;
    }
    .ui-sub-menu-icon {
      @extend %menu-icon;

      display: none;
      position: absolute;
      left: 0;
      top: $header-height;
      padding-left: 20px;
      border: none;
      line-height: 1.5rem;
      margin-top: 7px;

      span {
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        margin-top: 6px;

        &,
        &::before,
        &::after {
            background: $left-nav-link-color;
        }
      }
    }
    &,
    .ui-sub-menu-open & {
      transition: height 400ms ease-in-out;
    }
  }

  aside {
    padding: 71px 44px 0 42px;

    img {
      display: block;
      width: 100%;
    }

    h3 {
      font-size: 1.6rem;
      line-height: 1.9rem;
      margin-top: 34px;
    }
    h4 {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $text-color;
      font-weight: 300;
    }
    p {
      font-size: 1.6rem;
      line-height: 2.25;
      margin: 1.5em 0;
    }


  }
  section {
    width: 1015px;
    padding-left: 38px;
    padding-right: 65px;
  }
}
