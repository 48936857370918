@media (max-width: 1380px) {
  .left-col {
    section {
      padding-right: 38px;
    }
  }
}

@media (max-width: 1000px) {
  .left-col {
    display: block;

    nav,
    aside,
    section {
      display: block;
      width: 100%;
    }
    section {
      /*margin: 0 20px;*/
      padding: 0
    }
    padding: 0 20px;
  }
}
