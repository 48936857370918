@media (max-width: 1330px) {
  .properties-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // margin-right: 0;

    &::before,
    &::after {
      content: none;
    }

    > li {
      // margin-right: 0;
    }
  }
}

@media (max-width: 700px) {
  .properties-list {
    display: block;
    width: 100%;

    > li {
      width: 100%;
      float: none;
      height: auto;
      margin-right: 0
    }
    a {
      padding-bottom: 28px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}
