.properties-list {
  @extend %grid-list;
  @extend %properties-base;

  text-transform: uppercase;
  /* fix for margins on list items */
  margin-right: -54px;

  > li {
    margin-right: 41px;
    height: 424px;
    position: relative;
    margin-bottom: 41px;
    /*
    &:nth-child(4n) {
      margin-right: 0;
    }
    */
  }
  h3, h4, div {
    margin-left: 17px;
    margin-right: 17px;
  }
  img {
    width: 283px;
    height: 225px;
  }
  .slideshow-images {
    display: none;
  }

  padding-bottom: 60px;
}
