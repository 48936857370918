%grid-list {
  @extend %no-list;
  @extend %clearfix;

    /*margin-right: -42px;*/

  > li {
    float: left;
    width: 283px;
    background: $light-gray;
  }

  a {
    display: block;
    height: 100%;
  }
}
