@media (max-width: 1010px) {
  header {
    overflow: hidden;

    .ui-menu-icon {
      position: absolute;
      top: 29px;
      right: 0;
    }

    &,
    .ui-main-menu-open & {
      nav {
        float: none;
        margin: 0;
        padding: 120px 0 0 0;

        .col-1,
        .col-2,
        .col-3,
        .col-4,
        .col-5 {
          float: none;
          width: 100%;
          ul {
            float: none;
            line-height: 1.2;
          }
        }
        li {
          float: none;
          text-align: center;

          ul {
            display: none;
          }

          a {
            width: auto;
            display: inline-block;
            padding: 0 0 1.68rem 0;

            span {
              border-right: none;
              color: white;
              padding: 0;
            }
          }
        }
        .active a::after {
          content: none;
        }
      }
    }
  }
}
@media (max-width: 550px) {
  header {
    .ui-main-menu-open &,
    & {
      .ui-menu-icon {
        top: 0;
        margin-top: 26px;
      }
    }
  }
}
