/*
@media (max-width: 696px) {
  .links-list {
    margin: 0;
    > li {
      margin-right: 0;
      width: 100%;
    }
  }
}
*/
