@media (max-width: 1000px) {
  .left-col {
    nav {
      padding-top: $header-height;
      height: 134px;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;

      .ui-sub-menu-open & {
        height: 100%;
      }

      .ui-sub-menu-icon {
        display: block;
      }

      ul {
        padding-top: 50px;
      }
    }
  }
  .ui-sub-menu-open {
    .left-col {
      nav {

      }
    }
  }
  .ui-sub-menu-open {
    overflow: hidden;

    header,
    .left-col nav {
      position: fixed;
      overflow-y: auto;
    }
  }
}

@media (max-width: 550px) {
  .left-col nav {
    padding-top: $mobile-header-height;
    .ui-sub-menu-icon {
      top: $mobile-header-height;
    }
  }
}
