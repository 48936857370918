.two-column {
  @extend %clearfix;
  margin-bottom: 100px;

  section {
    width: 50%;
    float: left;
    &:first-child,
    &:first-of-type {
      padding: 0 66px 0 23px;
    }
    &:last-child {
      padding: 0 63px 0 16px;
    }
  }
}
