%properties-base {
  li {
    background: $light-gray;
  }
  a {
    display: block;

    transition: opacity 300ms ease-in-out;
    &:hover {
      opacity: 0.75;
    }
  }
  img {
    display: block;
  }
  h3, h4 {
    margin: 0;
    /*line-height: 1;*/
  }
  h3 {
    font-size: 2.1rem;
    padding-top: 28px;
    margin-bottom: 5px;
    @include letter-spacing(100);
  }
  h4 {
    font-weight: 300;
    font-size: 1.5rem;
    color: $maroon-color;
  }
  div {
    font-family: 'Oswald', 'Open Sans', Arial, 'sans-serif';
    color: $dark-gray;
    font-size: 1.5rem;
    /*letter-spacing: 0.15em;*/
    @include letter-spacing(150);
    line-height: 1;
    margin-top: 24px;
    span {
      line-height: 1;
      display: inline-block;
      padding: 0 19px;
      border-right: 1px solid $dark-gray;
      &:first-child {
        padding-left: 0;

      }
      &:last-child {
        padding-right: 0;
        border-right: 0;
      }
    }
  }
}
