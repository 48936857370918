footer {
  @extend %clearfix;
  @extend %navigation;

  background: $maroon-color;
  color: white;
  text-align: center;
  padding-bottom: 185px;
  padding-top: 65px;
  /*margin-top: 78px;*/

  nav {
    font-size: 1.2rem;
    display: table;
    margin: 0 auto 36px auto;
  }
  .windermere-logo {
    margin-bottom: 18px;
  }
  p {
    font-size: 1rem;
    line-height: 1.4rem;
    margin: 0;
  }
  a {
    color: white;
  }
}
