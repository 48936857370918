form {
  @extend %clearfix;

  background: $light-gray;
  padding: 0 25px 20px 21px;

  margin-bottom: 150px;

  h3 {
    margin: 2em 0 1em;
  }

  .error {
    margin: 0.5em 0 0.5em 2em;
  }

  label {
    display: block;
    color: $maroon-color;
    font-family: 'Oswald';
    font-size: 1.8rem;
    line-height: 1;
    text-transform: uppercase;
    @include letter-spacing(150);
    padding-top: 22px;
  }
  input, textarea {
    width: 100%;
    border: none;
    padding: 0 10px 0 10px;
    font-family: 'Open Sans';
    margin-top: 15px;
  }
  input {
    line-height: 5rem;
  }
  textarea {
    height: 140px;
    resize: vertical;
    line-height: 1.5;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  button,
  a.button {
    float: right;
    margin-top: 30px;
  }
}
