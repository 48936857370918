.slideshow-container {
  display: none;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  z-index: 2000;


  &.hidden-ui {
    .close,
    .preview-sheet,
    .back {
      display: none;
    }
    .caption {
      border-right: none;
    }
  }


  .slideshow-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .slideshow-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 56px;
    // max-width: 100%;

    // no transform
    // margin-top: -238px;
    // margin-left: -500px;

    &::after {
      content: "Loading...";
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-weight: bold;
      color: #E4E4E4;
      font-size: 2em;
      text-transform: uppercase;
      margin-top: 15px;
    }
  }
  .slideshow {
    display: none;
    background: $light-gray;
    font-family: 'Oswald', 'Open Sans', 'Arial', sans-serif;
    // margin-top: $lightbox-titlebar-height;

    width: 1000px;


    @include letter-spacing(150);

    img {
      display: block;
      width: 1000px;
      height: 420px;
    }
  }
  .titlebar {
    @extend %clearfix;
    background: $light-gray;
    position: absolute;
    height: $lightbox-titlebar-height;
    top: 0;
    left: 0;
    width: 100%;

    font-size: 1.2rem;
    padding: 0 110px 0 16px;

    text-transform: uppercase;

    line-height: 1;

    padding-top: 20px;
    padding-bottom: 20px;
  }
  .title,
  .address,
  .price,
  .status,
  .preview-sheet {
    float: left;
    border-left: 1px solid $text-color;
    padding: 0 14px;
    margin: 2px 0;
  }

  .title-address,
  .price-status,
  .preview-sheet {
    float: left;
  }

  .right {
    position: absolute;
    right: 0;
    top: 0;
  }

  .caption {
    margin: 22px 0;
  }

  .title {
    border-left: none;
  }
  .left {
    float: left;
  }
  .right {
    float: right;
  }
  .caption,
  .close {
    float: left;
  }

  .caption {
    border-right: 1px solid $text-color;
    padding-right: 15px
  }

  .close {
    margin-top: 0;
    margin-bottom: 0;
    padding: 22px 20px 22px 15px;
    font-weight: bold;
  }
  .next,
  .prev {
    @extend %sprite;
    @extend %content-box;

    position: absolute;
    top: 50%;

    width: 60px;
    height: 43px;
    background-repeat: no-repeat;

    margin-top: -22px;

    transition: opacity 300ms ease-in-out;

    &:hover {
      opacity: 0.75;
    }
  }

  .next {
    right: -60px;
    margin-right: 0;
    background: url('/assets/images/ui/sprite.png') -130px 0 no-repeat;
  }
  .prev {
    left: -60px;
    background: url('/assets/images/ui/sprite.png') -70px 0 no-repeat;
  }
  .back {
    position: absolute;
    top: 100%;
    color: white;
    text-transform: uppercase;
    font-size: 1.2rem;
  }
}

.ui-slideshow-open {
  overflow-y: hidden;
}
