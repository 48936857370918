%menu-icon {
  /*padding: 5px 0;*/

  span {
    display: block;
    height: 3px;
    width: 15px;
    position: relative;
    font-size: 0;

    &::before,
    &::after {
      position: absolute;
      left: 0;
      content: " ";
      width: 100%;
      height: 100%;
    }

    &::before {
      transform: translateY(-5px);
    }
    &::after {
      transform: translateY(5px);
    }
  }
}

%menu-icon-close {
  span {
    border: 3px solid white;
    opacity: 0.5;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    background: transparent;
  }
  span:before,
  span:after {
    content: " ";
    background: white;
    width: 3px;
    height: 12px;
    left: 50%;
    top: 50%;
  }
  span:before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
  span:after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
}
