%navigation nav {
  /*font-family: 'Oswald', sans-serif;*/
  @include letter-spacing(50);
  line-height: 1;
  /*text-transform: uppercase;*/

  li {
    float: left;
     a {
      color: $navigation-link-color;
      padding: 0 0.85em;
      border-right: 2px solid $orange;
      display: block;
      &:hover {
        color: #FFFFFF;
      }
    }
    &:last-child a {
      border-right: none;
    }
  }
}
