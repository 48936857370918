$maroon-color: #5D1210;
$darker-maroon: #660000;

$header-text-color: #660000;
$text-color: #333333;

$link-color: #990704;
$navigation-link-color: #BAA7A6;


$dark-gray: #484747;
$light-gray: #F0F0EE;
$lighter-gray: #F5F5F5;

$left-nav-background: #F2F2F2;
$left-nav-link-color: #999999;

$orange: #BF8D52;
