header {

  nav {
    float: right;
    font-size: 1.4rem;
    margin-top: 29px;
    @include letter-spacing(50);

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5 {
      float: left
    }

    ul {
      float: left;
    }
    li {
      ul {
        display: none
      }
      a {
        padding: 30px 0;
        border: none;
        span {
          display: block;
          height: 1.4rem;
          padding: 0 0.85em;
          border-right: 2px solid $orange;
        }
      }
    }

    .col-5 {
      ul:last-child {
        a {
          span {
            border-right: none;
          }
        }
      }
    }

    .active > a {
      position: relative;
      color: white;

      &::after {
        content: "";
        width: 40px;
        height: 19px;
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -20px;
        background: url('/assets/images/ui/sprite.png') 0 0 no-repeat;
      }
    }
    .ui-menu-icon {
      @extend %menu-icon;

      float: right;

      padding: 5px 0;

      margin: 30px 62px 0 24px;

      span {
        &,
        &::before,
        &::after {
          background: $orange;
        }
      }
    }
  }
  &,
  .ui-main-menu-open & {
    transition: height 400ms ease-in-out;
  }

  .ui-main-menu-open & {
    height: 100%;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;

    nav {
      float: none;
      margin: 0;
      padding: 120px 41px 0;

      ul {
        float: none;
        line-height: 1.2;
      }
      li {
        float: none;
        a {
          padding: 0 0 1.68rem 0;
          width: 159px;
          span {
            border-right: none;
            color: white;
            padding: 0;
          }
          &::after {
            content: none;
          }
        }
        ul {
          display: block;
        }
      }
      /*
      .active {
        ul {
          a {
            color: $navigation-link-color;
          }
        }
      }
      */
    }
    .ui-menu-icon {
      @extend %menu-icon-close;

      position: absolute;
      top: 23px;
      right: 0;
      margin-right: 57px;
      padding: 0;
    }
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5 {
      width: 20%;
    }
  }
}
.ui-main-menu-open {
  overflow: hidden;
}
