header {
  @extend %clearfix;
  @extend %navigation;

  position: absolute;
  z-index: 1100;
  width: 100%;
  height: $header-height;
  background: $maroon-color;
  border-bottom: 1px solid white;

  h1 {
    text-transform: uppercase;
    font-weight: normal;
    float: left;
    font: 2.4rem/1 'Oswald', sans-serif;
    @include letter-spacing(75);

    /*margin: 51px 0 0 44px;*/
    margin: 0;
    margin: 24px 4px 0 13px;

    a {
      color: rgba(255, 255, 255, 0.5);
      padding: 51px 0 0 44px;
      padding: 10px;
      padding: 28px;
      display: block;
      transition: color 300ms ease-in-out;
      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .tag-line {
    float: left;
    color: $orange;
    font-size: 1.2rem;
    line-height: 1;
    margin: 63px 0 0 0;
    margin-right: 12px;
    text-transform: lowercase;
    span {
      display: inline-block;
      &:first-child {
        padding-right: 6px;
        border-right: 1px solid $orange;
      }
      &:last-child {
        padding-left: 6px;
      }
    }
  }
}
