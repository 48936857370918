@media (max-width: 1100px) {
	.mystery-photo {
		.two-column {
			section:first-child,
			section:first-of-type,
			section:last-child,
			section:last-of-type {
				padding-left: 0;
				padding-right: 0;
			}
		}

		section.margins {
			margin-top: 60px;
		}
	}
}
