@media (max-width: 580px) {
  .vertical-property-list img {
    float: none;
    width: 100%;
    height: auto;
  }
  .vertical-property-list a {
    height: auto;
    padding-bottom: 28px;
    &:after {
      top: 100%;
      margin-top: -90px;
      right: 20px;
    }
  }
  .vertical-property-list h3,
  .vertical-property-list h4,
  .vertical-property-list div {
    margin-left: 17px;
    margin-right: 17px;
  }

}
