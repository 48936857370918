.mystery-photo {
	
	.two-column {
		section:first-child,
		section:first-of-type {
			padding-left: 0;
			padding-right: 23px
		}
		section:last-child,
		section:last-of-type {
			padding-left: 22px;
		}
	}

	.photo {
		width: 100%;
	}

	section {
		&.margins {
			margin-top: 161px;
		}

		h3,
		p {
			font-size: 2.6rem;
		}

		p {
			line-height: 1.8;
		}
	}

	.button {
		text-transform: uppercase;
	}

	p.smaller {
		font-size: 2rem;
		line-height: 2.3;
	}

	.vertical-property-list h3 {
		margin-right: 60px;
		font-size: 2.1rem;
	}

	.vertical-property-list h3,
	.vertical-property-list h4 {
		margin-left: 333px;
	}

	.vertical-property-list,
	.vertical-property-list li,
	.vertical-property-list img {
		height: 180px;

	}

	.vertical-property-list img {
		width: auto;
	}
}
