$mobile-header-height: 75px;
@media (max-width: 1125px) {
  .tag-line {
      display: none;
    }
}

@media (max-width: 550px) {
  header {
    height: $mobile-header-height;

    h1 {
      margin: 0;

      a {
        padding: 23px 20px 27px 20px;
      }
    }

    nav {
      padding-top: $mobile-header-height;
    }
  }
}
