@media (max-width: 1100px) {
  .two-column {
    section {
      float: none;
      width: 100%;

      &:first-child,
      &:last-child {
        padding: 0 20px;
      }
    }
  }
}
