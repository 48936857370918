.vertical-property-list {
  @extend %no-list;
  @extend %properties-base;

  font-family: Oswald, Arial, sans-serif;
  text-transform: uppercase;

  a {
    height: 135px;
    position: relative;

    &::after {
      @include arrow;

      content: "";
      position: absolute;
      right: 62px;
      top: 50%;
      margin-top: -21px;
      width: 29px;
      height: 43px;
    }
  }
  li {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  img {
    width: 170px;
    height: 100%;
    float: left;
  }
  h3, h4, div {
    margin-left: 207px;
  }
  .slideshow-images {
    display: none;
  }
}
